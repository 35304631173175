import catalogApi from './api';
import * as types from './actionTypes';

// State
import { getDeliveryAreaIdSelector } from '../order/selectors';
import {
  FETCH_CATALOG_NOT_FOUND_SLIDER_FAILURE,
  FETCH_CATALOG_NOT_FOUND_SLIDER_REQUEST,
  FETCH_CATALOG_NOT_FOUND_SLIDER_SUCCESS,
} from './actionTypes';

export const fetchCatalogUpsale = () => {
  return (dispatch, getState) => {
    dispatch({ type: types.FETCH_CATALOG_UPSALE_REQUEST });

    return catalogApi
      .getCatalogUpsale({ delivery_area_id: getDeliveryAreaIdSelector(getState()) })
      .then(payload => {
        return dispatch({ type: types.FETCH_CATALOG_UPSALE_SUCCESS, payload });
      })
      .catch(() => {
        return dispatch({ type: types.FETCH_CATALOG_UPSALE_FAILURE });
      });
  };
};

export const fetchCatalogGoods = params => {
  return dispatch => {
    dispatch({ type: types.FETCH_CATALOG_GOODS_REQUEST });

    return catalogApi
      .getCatalogGoods(params)
      .then(data => {
        return dispatch({ type: types.FETCH_CATALOG_GOODS_SUCCESS, payload: data });
      })
      .catch(({ message }) => {
        console.error(message);
        return dispatch({ type: types.FETCH_CATALOG_GOODS_FAILURE });
      });
  };
};

export const fetchCatalogActiveIngredients = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_CATALOG_ACTIVE_INGREDIENTS_REQUEST });

    return catalogApi
      .getCatalogActiveIngredients()
      .then(data => {
        return dispatch({ type: types.FETCH_CATALOG_ACTIVE_INGREDIENTS_SUCCESS, payload: data });
      })
      .catch(() => {
        return dispatch({ type: types.FETCH_CATALOG_ACTIVE_INGREDIENTS_FAILURE, hasError: true });
      });
  };
};

export const fetchCatalogActiveIngredientsIfNeeded = () => {
  return (dispatch, getState) => {
    const {
      catalog: {
        pizzaFilter: { isLoading, isLoaded },
      },
    } = getState();

    if (!isLoading && !isLoaded) {
      return dispatch(fetchCatalogActiveIngredients());
    }

    return false;
  };
};

export const removeIngredient = (id, variationId, ingredientId) => ({
  type: types.REMOVE_INGREDIENT,
  payload: { id, variationId, ingredientId },
});

export const cancelRemovedIngredients = (id, variationId) => ({
  type: types.CANCEL_REMOVED_INGREDIENTS,
  payload: { id, variationId },
});

export const fetchCatalogNotFoundSliderRequest = () => {
  return dispatch => {
    dispatch({ type: types.FETCH_CATALOG_NOT_FOUND_SLIDER_REQUEST });

    return catalogApi
      .getCatalogNotFoundSlider()
      .then(data => {
        return dispatch({ type: types.FETCH_CATALOG_NOT_FOUND_SLIDER_SUCCESS, payload: data });
      })
      .catch(() => {
        return dispatch({ type: types.FETCH_CATALOG_NOT_FOUND_SLIDER_FAILURE, hasError: true });
      });
  };
};
