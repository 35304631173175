const POINTS_BURNING_PERIOD = 14;

const isSuperPoint = (item) => {
  const { expiry_info, action_date } = item;
  return (
    Math.floor((new Date(expiry_info[0].expire_date).valueOf() - new Date(action_date).valueOf()) / 86400000) <=
    POINTS_BURNING_PERIOD
  );
};

export const filterWillBurnPoints = (history, points) => {
  if (history && points) {
    const data = JSON.parse(JSON.stringify(history));
    const reversedData = data?.reverse();
    const willBurnArray = [];
    const spentPoints = points ? points.spent + points.spent_extra : 0;
    let confirmedSumm = 0;
    let willBurnSumm = 0;
    let counter = 0;
    reversedData?.forEach((item) => {
        const { is_completed, points_delta, expiry_info } = item;

        if (points_delta > 0 && is_completed) {
          //если начисление
          confirmedSumm += points_delta;
        }

        if (confirmedSumm > spentPoints) {
          //если сумма начислений больше чем сумма списаний
          counter++;
          if (points_delta > 0 && is_completed) {
            if (counter === 1) {
              //если это первое начисление с которого списались баллы
              item.points_delta = confirmedSumm - spentPoints;
            }
            if (expiry_info && expiry_info.length > 0) {
              if (isSuperPoint(item)) {
                item.points_type = 'super_points';
              } else {
                item.points_type = 'papa_points';
              }
              item.expire_date = expiry_info[0].expire_date;
              item.points_delta = expiry_info[0].points_expirable_remain;
              willBurnSumm += expiry_info[0].points_expirable_remain;

              willBurnArray.push(item);
            }
          }
        }
      }
    );
    return {
      willBurn: willBurnArray,
      willBurnPoints: willBurnSumm
    };
  }
};


export const useBonuses = (points, compositionCart, willBurn) => {
  if (points && compositionCart && willBurn) {
    const { papa_points, super_points } = points;
    let papaBonuses = papa_points?.value;
    let superBonuses = super_points?.value;
    let spentPoints = compositionCart?.reduce((accum, good) => {
      if (good.type === 'bonus') {
        return accum + (good.count * good.price);
      }
      return accum + 0;
    }, 0);

    let papaBonus = papaBonuses;
    let superBonus = superBonuses;

    willBurn.forEach((item) => {
      if (spentPoints > 0) {
        const { points_delta, points_type } = item;
        const minusPoints = spentPoints > points_delta ? points_delta : spentPoints;

        if (points_type === 'papa_points') {
          papaBonus -= minusPoints;
        } else if (points_type === 'super_points') {
          superBonus -= minusPoints;
        }
        spentPoints -= minusPoints;
      }
    });

    if (spentPoints > 0) {
      papaBonus -= spentPoints;
    }
    return { papaBonus, superBonus };
  }
};



