import { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _isEqual from 'lodash/isEqual';

// State
import { cancelRemovedIngredients, removeIngredient } from '../../../state/modules/catalog/actions';
import { addCartItem } from '../../../state/modules/cart/actions';
import { showFreeSaucesPanel } from '../../../state/modules/freeSauces/actions';
import { fetchGiftsIsNeeded } from '../../../state/modules/gifts/actions';

export default function useProductList(category) {
  const dispatch = useDispatch();
  const goods = useSelector(state => state.catalog.products.list);
  const sailplayGifts = useSelector(state=>state.gifts.list)

  const [filteredStateGood, setFilteredStateGood] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [filteredIds, setFilteredIds] = useState([])

  useEffect(() => {
    dispatch(fetchGiftsIsNeeded());
  }, []);

  const getGoodsWithoutDublicates = () => {
    if (filteredIds.length) {
      filteredGoods(filteredIds)
    } else {
      setFilteredCategory(category.map(elem => elem.alias));
      setFilteredStateGood(goods);
    }
  };

  const filteredGoods = (id) => {

    const filter = goods.filter(item => {
      if (id.includes(item.id)) {
        return item;
      }
    });

    const category = filter.map((elem) => elem.category);

    setFilteredCategory([...new Set(category)]);
    setFilteredStateGood(filter);
    setFilteredIds(id)
  };

  const removeIngredientAction = useCallback(({ id, variation, ingredientId }) => {
    return dispatch(removeIngredient(id, variation.id, ingredientId));
  }, []);

  const cancelRemovedIngredientsAction = useCallback(({ id, variation }) => {
    return dispatch(cancelRemovedIngredients(id, variation.id));
  }, []);

  const addToCart = useCallback(async (variation, onSuccessCallback) => {
    await dispatch(
      addCartItem({ composition: [{ type: 'good', item: variation }] }, onSuccessCallback)
    );

    if (variation.sauces?.length > 0) {
      await dispatch(
        showFreeSaucesPanel(variation.id, variation.sauces, variation.free_sauce_count)
      );
    }
  }, []);

  return {
    goods,
    filteredStateGood,
    getGoodsWithoutDublicates,
    filteredGoods,
    filteredCategory,
    removeIngredientAction,
    cancelRemovedIngredientsAction,
    addToCart,
    sailplayGifts,
  };
}
